<script>
import VisitorLevelForm from "@/views/menu/visitorLevel/visitorLevelForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    visitorLevelService: "visitorLevelService",
    toastService: "toastService"
  },
})
export default class VisitorLevelNew extends mixins(VisitorLevelForm) {
  formId = "new-visitorLevel-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations?.labels?.button_save,
        leftIcon: "mdi-content-save-outline",
        handle: this.save,
      },
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
      await this.visitorLevelService.create(this.model);
      this.$router.push("/visitor-level/list");
      // eslint-disable-next-line no-undef
      toastService.success(this.translations.success.visitorLevel_create);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle() {
    return this.translations?.pageTitles?.visitorLevels_new;
  }

  afterCreate () {
    this.model = {
      code: null,
      idEvent: null,
      localizations: [],
    };
    this.isReady = true;
  }
}
</script>
